const CALENDAR_CONFIG = {
  canvasSize: {
    width: 400,
    height: 660,
  },
  canvasResolution: 0.2,
  textColor: '#000000',
}

export { CALENDAR_CONFIG };
