const SECRET_CODE_CONFIG = {
  maxSequence: 10,
}

const SECRET_CODE_TYPE = {
  Konami: "KONAMI",
}

const SECRET_CODES_CONFIG = {
  [SECRET_CODE_TYPE.Konami]: [75, 75, 85, 85, 84, 86, 84, 86, 68, 51],
}

export { SECRET_CODE_CONFIG, SECRET_CODE_TYPE, SECRET_CODES_CONFIG };
